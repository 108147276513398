import { useState } from 'react'
import { PlaneShare, PreviewArrowRight } from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'

export const LayoutTikTok = ({
  image,
  legend,
  userData,
  setValueItem,
  type,
  selectedValues,
}) => {
  const [showButtons, setShowButtons] = useState(false)

  function nextImage(type) {
    if (type === 4) {
      setValueItem(1)
    } else {
      setValueItem(type + 1)
    }
  }

  function previewImage(type) {
    if (type === 1) {
      setValueItem(4)
    } else {
      setValueItem(type - 1)
    }
  }
  return (
    <div className={styles.box_image_render}>
      <p className={styles.previewText}>Prévia TikTok</p>
      <div className={styles.body_image_render}>
        <div
          className={styles.carouselContainer}
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
        >
          <button
            className={styles.carouselButtonLeft}
            onClick={() => nextImage(type)}
            style={{
              opacity: showButtons ? 1 : 0,
              transition: 'opacity 0.3s',
              transform: 'rotate(180deg)',
            }}
          >
            <div style={{ marginBottom: -28 }}>
              <PreviewArrowRight />
            </div>
          </button>
          <img src={image} className={styles.carouselImage} alt="Imagem" />
          <button
            className={styles.carouselButtonRight}
            onClick={() => previewImage(type)}
            style={{
              opacity: showButtons ? 1 : 0,
              transition: 'opacity 0.3s',
            }}
          >
            <PreviewArrowRight />
          </button>
        </div>
      </div>

      <div className={styles.footer_image_render}>
        <div className={styles.box_icons}>
          <div>
            {/* <HeartLike />
            <PlaneShare /> */}
            {/* <CommentBubble />
            <PlaneShare /> */}
          </div>
          <div>
            {/* <HeartLike /> */}
            <PlaneShare />
            {/* <SaveFlag /> */}
          </div>
        </div>
        <p className={styles.legend_publication}>
          {/* {truncateString(legend, 60)} */}
        </p>
      </div>
    </div>
  )
}

LayoutTikTok.propTypes = {
  image: object,
}
