import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import xamaLogoBranco from '../../assets/logos/xama-logo-branco.png'

import {
  NotificationService,
  PagarMeService,
  SubscriptionService,
  UserAccountsService,
} from '../../services'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',

  STEP_NOTICE: 'NoticeStep',

  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepNotice({ setStep }) {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [showPlans, setShowPlans] = useState(false)
  const [firstLogin, setFirstLogin] = useState()

  async function handleNavigate(route) {
    navigation(route)
  }

  useEffect(() => {
    loadFirstLogin()
  }, [])

  const loadFirstLogin = async () => {
    const { data: dataFirstLogin, responseStatus } =
      await UserAccountsService.getUserAccountsByLogin()

    setFirstLogin(dataFirstLogin)

    updateFirstLogin(dataFirstLogin)
  }

  const updateFirstLogin = async (dataFirstLogin) => {
    const { ...rest } = dataFirstLogin

    delete rest.uuid
    delete rest.createdAt
    delete rest.updatedAt
    delete rest.deletedAt
    delete rest.PaymentHistory

    rest.firstLogin = false
    rest.photoName = ''
    rest.photoUrl = ''

    const { data, responseStatus } =
      await UserAccountsService.putUserAccountsByLogin(rest)

    // await NotificationService.postNotification({
    //   appointmentNotification: true,
    //   passwordReset: true,
    //   notifyUpdate: true,
    //   PublishedAppointmentNotifications: true,
    //   NewsSuggestionsUpdates: true,
    // })
  }

  const handleSteps = () => {
    // if (firstLogin) {
    //   updateFirstLogin()
    //   return
    // }
    setStep(STEPS.STEP_SOCIAL_MEDIA)
  }

  // const updateFirstLogin = async () => {
  //   const { ...rest } = firstLogin

  //   delete rest.uuid
  //   delete rest.createdAt
  //   delete rest.updatedAt
  //   delete rest.deletedAt
  //   delete rest.PaymentHistory

  //   rest.firstLogin = false
  //   rest.photoName = ''
  //   rest.photoUrl = ''

  //   const { data, responseStatus } =
  //     await UserAccountsService.putUserAccountsByLogin(rest)

  //   await NotificationService.postNotification({
  //     appointmentNotification: true,
  //     passwordReset: true,
  //     notifyUpdate: true,
  //     PublishedAppointmentNotifications: true,
  //     NewsSuggestionsUpdates: true,
  //   })
  // }

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
        <img src={xamaLogoBranco} alt="xamaLogoText" style={{ height: 34 }} />
      </div>
      <div className={styles.divider} />

      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content_title}>
            <h1 style={{ fontSize: 32 }}>{t('step_notice.title')}</h1>
          </div>
          <div className={styles.content_subtitle}>
            <h2 style={{ fontSize: 18 }}>{t('step_notice.subtitle')}</h2>

            <h2 style={{ fontSize: 18, marginTop: 26 }}>
              {t('step_notice.subtitle_2')}
            </h2>
          </div>
        </div>

        <div className={styles.container_buttons}>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#190027',
              borderRadius: 4,
              padding: '12px 24px',
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={() => handleSteps()}
          >
            {t('step_notice.continue')}
          </button>

          <button
            style={{
              backgroundColor: '#190027',
              border: '1px solid #fff',
              borderRadius: 4,
              padding: '14px 24px',
              fontSize: 12,
            }}
            onClick={() => handleNavigate('/HomePage')}
          >
            {t('step_notice.later')}
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  )
}

export default StepNotice
