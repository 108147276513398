import { useEffect, useState } from 'react'

import styles from './styles.module.css'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

import { PythonService } from '../../../../services'
import { Loading } from '../../../../components/Loading'

export const EffectsStep = ({ effects, setImageUrl, editTemplate }) => {
  const [loading, setLoading] = useState(false)
  const [tiktokMusic, setTiktokMusic] = useState(false)

  useEffect(() => {}, [effects])

  const handleChecked = async (e, index, enabled) => {
    setLoading(true)

    const newEffects = { ...effects }
    newEffects.params[e.originalIndex].activeEffect = enabled

    const returnNewTemplate = await PythonService.postGenerateArtEditTemplate([
      effects,
    ])

    setImageUrl(
      'data:image/png;base64,' +
        returnNewTemplate?.data?.data[0]?.srcComTratativa
    )

    editTemplate(index, returnNewTemplate?.data?.data[0])

    setLoading(false)
  }

  const SwitchField = ({ e, index }) => {
    return (
      <div key={index} className={styles.effectItem}>
        <div
          style={{
            marginTop: 18,
            backgroundColor: e.activeEffect ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
            display: 'flex',
            alignItems: 'center',
            gap: 20,
          }}
          className={styles.selectedModalCard}
          onClick={() => handleChecked(e, index, !e.activeEffect)}
        >
          <div
            style={{
              width: 34,
              height: 34,
              border: '1px solid #dcdcdc',
              borderRadius: 200,
              backgroundColor: e.activeEffect ? '#6A0098' : '#FBFBFB', //'#6A0098',
            }}
          ></div>

          <div>
            <div className={styles.selectedModalTextTitle}>{e.effect}</div>
            {/* <div className={styles.selectedModalTextSubtitle}>
              {t('stepMyPublication.music_description')}
            </div> */}
          </div>
        </div>

        {/* <span>
          <Switch
            size="small"
            checked={e.activeEffect}
            onClick={() => {
              handleChecked(e, index, !e.activeEffect)
            }}
          />
        </span> */}
        {/* {e.effect} */}
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container}
    >
      <Loading enable={loading} />
      <span className={styles.label}>Efeitos adicionados</span>
      <div className={styles.tagsContainerEffectsContainer}>
        <div className={styles.effectsContainer}>
          {effects.params
            .map((e, originalIndex) => ({ ...e, originalIndex }))
            .filter(
              (e) =>
                e.effect !== null &&
                e.effect !== undefined &&
                e.effect !== 'none'
            )
            .map((e, index) => (
              <div key={index} className={styles.effectItem}>
                {/* <span style={{ color: 'black' }}>{e.effect}</span> */}
                <SwitchField e={e} index={index} />
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  )
}

EffectsStep.propTypes = {
  effects: PropTypes.array,
  aditionalEffects: PropTypes.array,
  onClickDeleteTag: PropTypes.func,
  onClickPlus: PropTypes.func,
}
