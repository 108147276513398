import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import {
  CalendarIcon,
  Clock,
  Clipboard,
  Trash,
  NavigateArrowLeft,
  NavigateArrowRigth2,
} from '../../assets/svgs/icons'
import { BusinessService, PublicationService } from '../../services'
import { useClient } from '../../hooks/useClient'
import { getBusinessByClientId } from '../../services/business'
import { useTranslation } from 'react-i18next'
import { notifyErrorCustom, notifySuccessCustom } from '../../utils/notify'

export const CalendarPublication = ({
  setSelectObjectCalendar,
  selectObjectCalendar,
  dataPublication,
  isOpen,
  setIsOpen,
  setContentPublication,
  setLoading,
  loadData,
}) => {
  const { t } = useTranslation()

  const monthNames = [
    t('calendar.january'),
    'Fevereiro',
    t('calendar.march'),
    t('calendar.april'),
    t('calendar.may'),
    t('calendar.june'),
    t('calendar.july'),
    t('calendar.august'),
    t('calendar.september'),
    t('calendar.october'),
    t('calendar.november'),
    t('calendar.december'),
  ]

  const [currentDate, setCurrentDate] = useState(new Date()) // Get the current date
  const [selectedDates] = useState([]) // Store selected dates
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  const { clientData } = useClient()

  const today = new Date()

  // useEffect(() => {
  //   // Limpa as refs antigas
  //   columnsRef.current = [];
  // }, [currentMonth, currentYear]);

  function hadleShowPubication(item, date) {
    setSelectObjectCalendar(item)
    handleDateClick(date)
  }

  const togglePopup = (data, date) => {
    setIsOpen(!isOpen)
    setContentPublication(data)
    handleDateClick(date)
  }

  const truncateTitle = (title, maxLength) => {
    if (!title) {
      return ''
    }

    if (title.length > maxLength) {
      return title.slice(0, maxLength) + '...'
    }
    return title
  }

  const columnsRef = useRef([])

  const handleDragStart = (e) => {
    e.target.classList.add('dragging');
  };

  const handleDragEnd = (e, item) => {
    e.target.classList.remove('dragging');
    const targetDay = e.target.closest('[data-day]')?.dataset.day;
    
    // Criar data alvo e data atual para comparação
    const targetDate = new Date(currentYear, currentMonth, parseInt(targetDay));
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Zera as horas para comparar apenas as datas
    
    // Verifica se a data alvo é anterior à data atual
    if (targetDate < today) {
      // Opcional: adicione aqui algum feedback visual ou mensagem de erro
      notifyErrorCustom('Não é possível agendar para datas passadas')
      return;
    }

    const draggedItemData = {
      element: e.target,
      targetDay: targetDay,
      item: item
    };
    putDatePost(draggedItemData, item);
  };

  const convertToISO8601 = (year, month, day, hour) => {
    // Cria uma nova data diretamente com os valores numéricos
    const date = new Date(year, month, day, hour);
    
    // Retorna a data em formato ISO 8601
    return date.toISOString();
  };



  const compareDate = (draggedDataDay, monthNames, dateItem) => {
    // Extrair o dia do dateItem
    const [day] = dateItem.split('-');
    
    // Agora a comparação será entre números
    if(parseInt(draggedDataDay) === parseInt(day)){
      return true;
    }
    
    return false;
  }

  const putDatePost = async (draggedData, item) => {
    if (draggedData.targetDay) {
      const hour = item.hour ? parseInt(item.hour) : 12;
      
      const isoDate = convertToISO8601(
        currentYear, 
        currentMonth, 
        parseInt(draggedData.targetDay), 
        hour
      );
      
      const targetDate = new Date(currentYear, currentMonth, parseInt(draggedData.targetDay));
    
       let businessId

       if(compareDate(draggedData.targetDay, monthNames[currentMonth], item.date)=== true){

         return
       }

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      const publicationBody = {
        headline: item.description,
        legend: item.copy,
        publicationImageUrl: item.images[0].url,
        publicationImageUrlName: item.images[0].id,
        status: item.status,
        sketch: false,
        isCanceled: false,
        scheduleDate: "2025-01-14T11:51:12.470Z",
        publicationDate:  isoDate,
        jobId: "string",
        //guestId: "string",
        publicationMode: '0',
        //hashtag: "string",
        //link: "string",
        //messagePermission: true,
        isPublic: true,
        classification: 0,
        generateArtJson: item.generateArtJson,
        //objective: "string",
        //businessClientId: "string"
      }
      try {
        await PublicationService.putPublication(
          item.id,
          businessId,
          '1',
          publicationBody
        )
        notifySuccessCustom('Publicação agendada com sucesso!')
        
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    }
  };

  useEffect(() => {
    // Pega todos os valores do objeto columnsRef.current
    const columns = Object.values(columnsRef.current).filter(Boolean);

    const handleDragOver = (e) => {
      e.preventDefault();
      const dragging = document.querySelector('.dragging');
      const applyAfter = getNewPosition(e.currentTarget, e.clientY);
    
      if (applyAfter) {
        applyAfter.insertAdjacentElement('afterend', dragging);
      } else {
        e.currentTarget.prepend(dragging);
      }
    };

    const getNewPosition = (column, posY) => {
      const cards = column.querySelectorAll('.item:not(.dragging)');
      let result;
      
      for (let refer_card of cards) {
        const box = refer_card.getBoundingClientRect();
        const boxCenterY = box.y + box.height / 2;
        
        if (posY >= boxCenterY) result = refer_card;
      }

      return result;
    };

    columns.forEach((column) => {
      if (column) {
        column.addEventListener('dragover', handleDragOver);
      }
    });

    return () => {
      columns.forEach((column) => {
        if (column) {
          column.removeEventListener('dragover', handleDragOver);
        }
      });
    };
  }, [currentMonth, currentYear]); // Adiciona currentMonth e currentYear como dependências

  const renderDays = () => {
    const days = []

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day)
      const isPastDate = date < today
      const isCurrentDate = date.getTime() === today.getTime()
      const isSelected = selectedDates.some(
        (selectedDate) => selectedDate.getTime() === date.getTime()
      )

      let contentReturn = findDate(date.getMonth() + 1, day, date.getFullYear())
     

      days.push(
        <div
          className={`${styles.day_box} ${
            isCurrentDate
              ? styles.current_day
              : date.getTime() === currentDate.getTime()
              ? styles.selected
              : isPastDate
              ? styles.disabled
              : ''
          }`}
          key={date.getTime()}
        >
          <div className={styles.container_day_box_date}>
            <div className={styles.day_box_date}>
              <p className={styles.day_box_date_day}style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{day}</p>
            </div>
            <div
              className={styles.day_box_schedules}
              id={day}
              data-day={day}
              ref={(el) => {
                if (!el) return; // Certifique-se de que o elemento existe

                const dateToCheck = new Date(currentYear, currentMonth, day)
                dateToCheck.setHours(0, 0, 0, 0)
                if (dateToCheck >= today  ) {
                  columnsRef.current[day] = el
                }
               
              }}
            >
              
              {contentReturn &&
              contentReturn?.content &&
              contentReturn?.content?.length > 0 &&
              contentReturn?.content?.length < 3
                ? contentReturn?.content?.map((item, key) => (
                  <div
                    key={key}
                    className={`${styles.schedule_of_the_day} item`}
                    draggable= {item?.status === 'Agendado' ? 'true' : 'false'}
                    onDragStart={handleDragStart}
                    onDragEnd={(e) => handleDragEnd(e,item)}
                    onClick={() => hadleShowPubication(item, date)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        hadleShowPubication(item, date);
                      }
                    }}
                    style={{
                      userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none',
                      backgroundColor: item.status === 'Sugestão de Publicação'
                        ? '#190027'
                        : item.status === 'Publicado'
                        ? '#3CBC00'
                        : '#ff8b00',
                    }}
                  >
                    {item?.status === 'Publicado' ? (
                      <Clipboard width={'15px'} height={'15px'} />
                    ) : item?.status === 'Cancelado' ? (
                      <Trash width={'15px'} height={'15px'} />
                    ) : (
                      <Clock width={'15px'} height={'15px'} />
                    )}

                    <p
                      style={{
                        userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none',
                        display: 'flex',
                        gap: 5,
                        cursor: 'pointer',
                        fontSize: 10,
                        margin: 0,
                        color:
                          item?.status === 'Sugestão de Publicação'
                            ? '#a1a0a0'
                            : '#fff',
                      }}
                    >
                      {truncateTitle(
                        item?.title
                          ? item?.title
                          : item?.videoUrl
                          ? 'Vídeo Próprio'
                          : 'Publicação Própria',
                        14
                      )}
                    </p>
                  </div>
                ))
                : contentReturn &&
                  contentReturn?.content &&
                  contentReturn?.content?.length >= 3 && (
                    <div
                      className={styles.schedule_of_the_day}
                      style={{
                        cursor: 'pointer',
                        background: isSelected ? '#FF8B00' : '#fc036b',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => togglePopup(contentReturn?.content, date)}
                    >
                      <div className={styles.box_schedule_of_the_day}>
                        <CalendarIcon width={'12px'} height={'12px'} />
                        <p className={styles.title_schedule_of_the_day}>
                          Ver mais
                        </p>
                      </div>
                      <p className={styles.title_schedule_of_the_day_plus}>
                        +3
                      </p>
                    </div>
                  )}
            </div>
          </div>
        </div>
      )
    }

    return days
  }

  const renderDaysLastMonth = () => {
    const daysLast = Array.from({
      length: new Date(currentYear, currentMonth, 1).getDay(),
    })
      .map((_, index) => {
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1
        const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate()
        const dayOfMonth = lastDayOfMonth - index

        const isSelected = selectedDates.some(
          (selectedDate) => selectedDate.getTime() === date.getTime()
        )

        const date = new Date(lastYear, lastMonth, dayOfMonth)
        let contentReturn = findDate(lastMonth + 1, dayOfMonth, lastYear)

        return (
          <div
            key={`empty-${index}`}
            className={`${styles.empty_day} ${styles.disabled}`}
          >
            <div className={styles.container_day_box_date}>
              <div className={styles.day_box_date}>
                <p className={styles.day_box_date_day} style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{dayOfMonth}</p>
              </div>
            </div>
            {contentReturn?.content &&
            contentReturn?.content?.length > 0 &&
            contentReturn?.content?.length < 3
              ? contentReturn?.content.map((item, key) => (
                  <div
                    key={key}
                    className={styles.schedule_of_the_day}
                    style={{
                      backgroundColor:
                        item?.status === 'Sugestão de Publicação'
                          ? '#190027'
                          : '#ff8b00',
                      opacity: '30%',
                    }}
                    onClick={() => hadleShowPubication(item, date)}
                  >
                    <Clock width={'15px'} height={'15px'} />
                    <p
                      style={{
                        display: 'flex',
                        gap: 5,
                        cursor: 'pointer',
                        fontSize: 10,
                        margin: 0,
                        color:
                          item?.status === 'Sugestão de Publicação'
                            ? '#a1a0a0'
                            : '#fff',
                      }}
                    >
                      {truncateTitle(
                        item?.title
                          ? item?.title
                          : item?.videoUrl
                          ? 'Vídeo Próprio'
                          : 'Publicação Própria',
                        14
                      )}
                    </p>
                  </div>
                ))
              : contentReturn?.content?.length >= 3 && (
                  <div
                    className={styles.schedule_of_the_day}
                    style={{
                      cursor: 'pointer',
                      background: isSelected ? '#FF8B00' : '#3CBC001A',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => togglePopup(contentReturn.content, date)}
                  >
                    <div className={styles.box_schedule_of_the_day}>
                      <CalendarIcon width={'12px'} height={'12px'} />
                      <p className={styles.title_schedule_of_the_day}>
                        Ver mais
                      </p>
                    </div>
                    <p className={styles.title_schedule_of_the_day_plus}>+3</p>
                  </div>
                )}
          </div>
        )
      })
      .reverse()

    return daysLast
  }

  const renderDaysNextMonth = () => {
    const daysNext = Array.from({
      length: 6 - new Date(currentYear, currentMonth, daysInMonth).getDay(),
    }).map((_, index) => {
      const dayOfMonth = index + 1
      const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
      const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear
      const date = new Date(nextYear, nextMonth, dayOfMonth)
      let contentReturn = findDate(
        nextMonth + 1, // Adicione 1 ao mês atual para obter o próximo mês
        dayOfMonth,
        nextYear
      )

      return (
        <div
          key={`empty-${daysInMonth + index}`}
          className={`${styles.empty_day} ${styles.disabled}`}
        >
          <div className={styles.container_day_box_date}>
            <div className={styles.day_box_date}>
              <p className={styles.day_box_date_day} style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{dayOfMonth}</p>
            </div>
          </div>

          {contentReturn?.content?.length > 0 &&
          contentReturn?.content?.length < 3
            ? contentReturn?.content.map((item, key) => (
                <div
                  key={key}
                  className={styles.schedule_of_the_day}
                  style={{
                    backgroundColor:
                      item?.status === 'Sugestão de Publicação'
                        ? '#190027'
                        : '#ff8b00',
                    opacity: '30%',
                  }}
                  onClick={() => hadleShowPubication(item, date)}
                >
                  <Clock width={'15px'} height={'15px'} />
                  <p
                    key={item?.id}
                    style={{
                      display: 'flex',
                      gap: 5,
                      cursor: 'pointer',
                      fontSize: 10,
                      margin: 0,
                      color:
                        item?.status === 'Sugestão de Publicação'
                          ? '#a1a0a0'
                          : '#fff',
                    }}
                  >
                    {truncateTitle(
                      item?.title
                        ? item?.title
                        : item?.videoUrl
                        ? 'Vídeo Próprio'
                        : 'Publicação Própria',
                      14
                    )}
                  </p>
                </div>
              ))
            : contentReturn?.content?.length >= 3 && (
                <div
                  className={styles.schedule_of_the_day}
                  style={{
                    cursor: 'pointer',
                    background: isSelected ? '#FF8B00' : '#3CBC001A',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => togglePopup(contentReturn?.content, date)}
                >
                  <div className={styles.box_schedule_of_the_day}>
                    <CalendarIcon width={'12px'} height={'12px'} />
                    <p className={styles.title_schedule_of_the_day}>Ver mais</p>
                  </div>
                  <p className={styles.title_schedule_of_the_day_plus}>+3</p>
                </div>
              )}
        </div>
      )
    })

    return daysNext
  }

  function handleNextMonth() {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)

      setLoading(true)
      loadData(0, currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)

      setLoading(true)
      loadData(currentMonth + 1, currentYear)
    }
  }

  function handlePreviusMonth() {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)

      setLoading(true)
      loadData(11, currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)

      setLoading(true)
      loadData(currentMonth - 1, currentYear)
    }
  }

  function handleDateClick(date) {
    if (date >= today) {
      setCurrentDate(date)
    }
  }

  const findDate = (mes, dia, ano) => {
    let date = mes + '-' + dia + '-' + ano

    let verifyDate = dataPublication?.find(
      (dateData) => dateData?.date === date
    )

    if (!verifyDate) {
      return []
    }

    return verifyDate
  }

  const HeaderCalendar = () => {
    return (
      <>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.sunday")}</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.monday")}</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.tuesday")}</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.wednesday")}</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.thursday")}</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.friday")}</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>{t("schedulerCalendar.saturday")}</p>
        </div>
      </>
    )
  }

  return (
    <div className={styles.calendar_wrapper}>
      <div className={styles.calendar_header}>
        <div>
          <h3 className={styles.calendar_header_name}>
            {monthNames[currentMonth] + ' ' + currentYear}
          </h3>
        </div>
        <div className={styles.calendar_button_navigate}>
          <div
            onClick={handlePreviusMonth}
            style={{ color: '#000', cursor: 'pointer' }}
          >
            <NavigateArrowLeft />
          </div>
          <div
            onClick={handleNextMonth}
            style={{ color: '#000', cursor: 'pointer' }}
          >
            <NavigateArrowRigth2 />
          </div>
        </div>
      </div>
      <div className={styles.calendar}>
        {HeaderCalendar()}

        {renderDaysLastMonth()}
        {renderDays()}
        {renderDaysNextMonth()}
      </div>
    </div>
  )
}
