/* eslint-disable react/prop-types */
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'

import {
  PagarMeService,
  PlanService,
  UserAccountsService,
  SubscriptionService,
  PublicationService,
  BusinessClient,
  BusinessUser,
  SocialMediaConnectionService,
  BusinessService,
} from '../../services/'

import { notifySuccessCustom, notifyWarningCustom } from '../../utils/notify'

import { useNavigate } from 'react-router-dom'

import AddCard from '../../components/AddCard'

import ModalComponent from '../../components/ModalComponent'

import React, { useEffect, useState } from 'react'

import { CheckIcon } from '../../assets/svgs/icons'

const formattedPrice = (price) => {
  // Converte o preço para float e arredonda para duas casas decimais
  const formatted = parseFloat(price).toFixed(2)

  // Transforma o preço em uma string com formato de moeda
  return parseFloat(formatted).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

function PlansPage({ welcomePage = false }) {
  const { t } = useTranslation()

  const [userAcount, setUserAccount] = useState()
  const [userCard, setCard] = useState()

  const [plansData, setPlansData] = useState([])

  useEffect(() => {
    loadPlans()
  }, [])

  const TIPO_DESCRIPTION = [
    {
      id: 0,
      value: t('plansPage.freeVersionDescription'),
    },
    {
      id: 1,
      value: t('plansPage.studentPlanDescription'),
    },
    {
      id: 2,
      value: t('plansPage.entrepreneurPlanDescription'),
    },
    {
      id: 3,
      value: t('plansPage.professionalPlanDescription'),
    },
    {
      id: 4,
      value: t('plansPage.corporatePlanDescription'),
    },
  ]

  const TIPO_SUPORTE = [
    {
      id: '1',
      value: 'Blog',
    },
    {
      id: '2',
      value: 'Blog',
    },
    {
      id: '3',
      value: 'E-mail',
    },
    {
      id: '4',
      value: 'Chat',
    },
    {
      id: '05',
      value: 'Chat e Whatsapp',
    },
  ]

  const loadPlans = async () => {
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())

    setCard(await PagarMeService.getCardByUser())

    const { data } = await PlanService.getPlansBaseValues()

    await data.forEach(async (plan, index) => {
      plan.priceReal = formattedPrice(plan.price)
      plan.resume = TIPO_DESCRIPTION[index].value
      await plan.PlanItems.forEach(async (item) => {
        if (
          !item.quantity &&
          (item.displayName == 'Agendamentos' ||
            item.displayName == 'Quantidade de Postagens' ||
            item.displayName == 'Clientes')
        ) {
          item.quantity = 'Ilimitado'
        }
        if (item.type == 'storage_cloud_quantity') {
          item.quantity = item.quantity > 0 ? item.quantity + ' GB' : ''
        }

        if (item.type == 'support') {
          item.quantity = TIPO_SUPORTE[item.quantity].value
        }
      })
    })

    const mockPlans = [
      {
        id: 1,
        description: 'Versão gratuita',
        price: 100,
        PlanItems: [
          {
            displayName: 'Quantidade de Postagens',
            quantity: '8',
          },
          {
            displayName: 'Quantidade de Usuários',
            quantity: '1',
          },
          {
            displayName: 'Agendamentos',
            quantity: '60',
          },
          {
            displayName: 'Clientes',
            quantity: '2',
          },
          {
            displayName: 'Quantidade de Integrações',
            quantity: '2',
          },
        ],
        priceReal: 'R$ 0,00',
        resume: 'Descrição do plano',
        premium: false,
        uuid: '1',
        tier: 1,
        quantity: '1',
        mock: true,
      },
    ]

    setPlansData([...data, ...mockPlans])

    return mockPlans
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={styles.page}>
        <h1 className={styles.mainTitle}>Selecione um plano</h1>
        <h2 className={styles.subtitle}>
          Escolha um plano que mais se adequa ao seu negócio
        </h2>

        <div className={styles.plansContainer}>
          {plansData.map((plano, index) => (
            <PlanCard
              key={index}
              plan={plano}
              titulo={plano.description}
              preco={plano.priceReal}
              features={plano.PlanItems}
              quantity={plano.quantity}
              resume={plano.resume}
              premium={plano.tier == 4 ? true : false}
              uuid={plano.uuid}
              tier={plano.tier}
              userAcount={userAcount}
              welcomePage={welcomePage}
            />
          ))}
        </div>
      </div>
    </motion.div>
  )
}

const PlanCard = ({
  plan,
  titulo,
  preco,
  features,
  resume,
  premium,
  uuid,
  tier,
  userAcount,
  welcomePage,
}) => {
  const { t } = useTranslation()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tierPlan, setTierPlan] = useState(null)
  const [tierCard, setTierCard] = useState(null)

  useEffect(() => {
    if (!modalIsOpen && tierCard > 0) {
      selectPlan(tierPlan)
    }
  }, [modalIsOpen])

  const closeModal = () => {
    setModalIsOpen(false)
    setTierCard(0)
  }

  const selectPlan = async (tier) => {
    if (!tier || modalIsOpen) {
      return
    }

    const userCardActive = await PagarMeService.getCardByUser()

    if (userCardActive.data.length <= 0) {
      setModalIsOpen(true)
      setTierCard(tier)
      //setShowCard(true)
      return
    }

    setLoading(true)
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (userSubscription.data.length > 0) {
      const currentTier = userSubscription.data[0].Plan.tier
      if (tier == currentTier) {
        notifyWarningCustom(t('plansPage.youCannotChooseTheSamePlan'))
        setLoading(false)
        return
      }

      if (tier < currentTier) {
        if (window.confirm(t('plansPage.areYouSureYouWantToChangeYourPlan'))) {
          const publicationsUser =
            await PublicationService.deletePublicationByUser()

          //await PublicationService.deletePublication(id)

          const clientsUser = await BusinessClient.deleteBusinessClientByUser()
          //await BusinessClient.deleteBusinessClient(id)
          const businessByclientsUser =
            await BusinessService.deleteBusinessByClientByUser()

          const businessUsersUser = await BusinessUser.deleteBusinessByUser()
          //await BusinessUser.deleteBusinessUser(id)

          const socialUser =
            await SocialMediaConnectionService.deleteSocialUser()
        } else {
          setLoading(false)
          return
        }
      }
      setLoading(false)
    }

    const body = {
      planId: uuid,
      accountId: userAcount?.data.uuid,
      cardId: userCardActive.data[0].uuid,
      paymentMethod: 'credit_card',
    }
    const data = await SubscriptionService.getSubscriptionByUser()

    if (data.data.length > 0) {
      const retPost = await SubscriptionService.putSubscription(
        data?.data[0]?.uuid,
        body
      )
      if (retPost.responseStatus == 204) {
        notifySuccessCustom(t('plansPage.planChangedSuccessfully'))

        if (welcomePage) {
          navigate('/StepsPage', {
            state: { fastPublication: true },
          })
        } else {
          navigate('/Settings')
        }
        return
      }
    } else {
      const retPost = await SubscriptionService.postSubscription(body)
      if (retPost.responseStatus == 200) {
        notifySuccessCustom(t('plansPage.planCreatedSuccessfully'))

        if (welcomePage) {
          navigate('/StepsPage', {
            state: { fastPublication: true },
          })
        } else {
          navigate('/Settings')
        }
        setLoading(false)

        return
      }
    }
  }
  const navigate = useNavigate()

  return (
    <>
      <ModalComponent
        isOpen={modalIsOpen}
        onClose={() => closeModal}
        className="Modal"
        overlayClassName="Overlay"
        style={{
          flex: 1,
          margin: '0 10px',
          maxWidth: 500,
          // background: 'red',
        }}
      >
        <div>
          <button
            onClick={closeModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            {t('plansPage.button.cancel')}
          </button>
        </div>
        <div>
          <AddCard
            setModalIsOpen={setModalIsOpen}
            selectPlan={selectPlan}
            tierCard={tierCard}
            setTierPlan={setTierPlan}
          />
        </div>
      </ModalComponent>

      <div className={premium ? styles.planCardPremium : styles.planCard}>
        {premium && (
          <div className={styles.premium}>
            <p className={styles.premiumText}>Mais indicado</p>
          </div>
        )}
        <h3 className={styles.planTitle}>
          {titulo.charAt(0).toUpperCase() + titulo.slice(1)}
        </h3>
        <p className={styles.planPrice}>
          <span className={styles.currency}>R$</span>
          {preco.replace('R$', '')}
          <span className={styles.currencyMonth}>/mês</span>
        </p>

        <p className={styles.planResume}>{resume}</p>

        <button
          onClick={() => {
            if (welcomePage) {
              if (plan.mock) {
                navigate('/StepsPage', {
                  state: { fastPublication: true },
                })
                return
              } else {
                selectPlan(tier)
              }
            } else {
              if (!plan.mock) {
                selectPlan(tier)
                return
              }
            }
          }}
          className={styles.addButton}
        >
          Selecionar
        </button>

        <div className={styles.featuresTitle}>Features:</div>
        <div className={styles.featuresDivider} />

        <ul className={styles.featuresList}>
          {features.map((feature, index) => (
            <li key={index} className={styles.featureItem}>
              <CheckIcon size="10" />
              {feature?.displayName}
              {': '}
              {feature?.quantity}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default PlansPage
