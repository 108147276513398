/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import SchedulerCalendar from '../../../components/SchedulerCalendar'
import CarouselHours from '../../../components/CarouselHours'

import { useNavigate } from 'react-router-dom'

import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../../utils/notify'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'

import { Loading } from '../../../components/Loading'

import {
  BusinessService,
  PublicationService,
  HeadlineHasPublicationService,
  VoiceToneService,
  TiktokService,
  MetaService,
} from '../../../services'

import {
  Picture,
  Instagram,
  Linkedin,
  Pinterest,
  Facebook,
  TikTok,
  Twitter,
  Calendar2,
  Subtract,
  PinMapsIcon,
  MorePersonsIcon,
  LinksIcon,
  HashtagIcon,
  FacebookChatIcon,
  Cross,
  LockClosed,
  TikTokIcon,
  XTwitter,
  User,
  PencilSquare,
} from '../../../assets/svgs/icons'

import { useState, useEffect } from 'react'

import { imgBase64Mock } from '../../../assets/mocks/imgMock'

import { ModalComponent } from '../../../components/ModalComponent'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { ImageRender } from '../../../components/ImageRender'
import dayjs from 'dayjs'
import { postPromptGPT } from '../../../services/pythonService'
import { ProductFruits } from 'react-product-fruits'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { MultiplySelectSquare } from '../../../components/Select/MultiplySelectSquare'
import TikTokModal from '../../../components/TikTokModal'

const MockArray = [
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
]

function StepFinish({
  setStep,
  targetAudienceRef,
  productRef,
  purposeOfPublicationRef,
  legend,
  imageUrl,

  publicationIdRef,
  imageLayers,

  editRef,
  editDataRef,
  currentIndex,
  dashParam,
  setLegend,
}) {
  const { clientData } = useClient()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [oppenedModalLocale, setOppenedModalLocale] = useState(false)
  const [oppenedModalPersons, setOppenedModalPersons] = useState(false)

  const [oppennedModalLinks, setOppennedModalLinks] = useState(false)
  const [oppennedModalHashtags, setOppennedModalHashtags] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())
  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')

  const [selectedValues, setSelectedValues] = useState({})
  const selectedValuesString = Object.keys(selectedValues).toString() || ''

  const [ImageLogoSrc, setImageLogoSrc] = useState({})
  const [tiktokCreatorInfo, setTiktokCreatorInfo] = useState(false)
  const [tiktokMusic, setTiktokMusic] = useState(false)
  const [dropDownTiktok, setDropDownTiktok] = useState(false)
  const [tiktokPrivacyLevel, setTiktokPrivacyLevel] = useState(false)
  const [tiktokComment, setTiktokComment] = useState(false)
  const [tiktokDuet, setTiktokDuet] = useState(false)
  const [tiktokStitch, setTiktokStitch] = useState(false)
  const [tiktokContentDisclosure, setTiktokContentDisclosure] = useState(false)
  const [tiktokYourBrand, setTiktokYourBrand] = useState(false)
  const [tiktokBrandedContent, setTiktokBrandedContent] = useState(false)
  const [valueItem, setValueItem] = useState(1)
  const [publicationTypePost, setPublicationTypePost] = useState(1)
  const { t } = useTranslation()
  const [tiktokBrandedInfoTerms, setTiktokBrandedInfoTerms] = useState(t(''))
  const [tiktokHeadline, setTiktokHeadline] = useState('')

  const [tiktokBrandedInfo, setTiktokBrandedInfo] = useState(
    'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
  )

  const [showTooltipFinish, setShowTooltipFinish] = useState(false)

  const [tiktokMusicCheckbox, setTiktokMusicCheckbox] = useState(false)

  const [confirmTerms, setConfirmTerms] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)
  const [facebookPages, setFacebookPages] = useState([])
  const [facebookPageToken, setFacebookPageToken] = useState(false)

  useEffect(() => {
    if (confirmTerms) {
      handleFinish()
    }
  }, [confirmTerms])

  const handleFinish = () => {
    // if (tiktokMusic && !tiktokMusicCheckbox) {
    //   notifyErrorCustom(t('stepMyPublication.alert_music_check'))
    //   return
    // }
    if (tiktokContentDisclosure && !tiktokYourBrand && !tiktokBrandedContent) {
      return
    } else {
      setLoading(true)
      if (editRef.current) {
        handleEditPublication()
      } else {
        handleSavePublication()
      }
    }
  }

  useEffect(() => {
    if (editRef.current) {
      // Criar um objeto Date
      const date = new Date(editDataRef?.current.publicationDate)

      // Obter a hora
      const hours = date.getUTCHours().toString().padStart(2, '0')
      const minutes = date.getUTCMinutes().toString().padStart(2, '0')

      if (selectedValues[parseInt(editDataRef?.current?.socialTypes)]) {
        const newValues = { ...selectedValues }
        delete newValues[parseInt(editDataRef?.current?.socialTypes)]
        setSelectedValues(newValues)
      } else {
        setSelectedValues({
          ...selectedValues,
          [parseInt(editDataRef?.current?.socialTypes)]: true,
        })
      }

      setSelectedDate(date.toDateString())
      setValueHour(hours)
      setValueMinute(minutes)
    }
  }, [])

  useEffect(() => {
    if (!tiktokYourBrand && !tiktokBrandedContent) {
      //setTiktokYourBrand(true)
      setTiktokBrandedInfo(t('stepMyPublication.tiktokBrandedInfo'))
      setTiktokBrandedInfoTerms('')
    } else if (!tiktokYourBrand && tiktokBrandedContent) {
      //setTiktokYourBrand(true)
      setTiktokBrandedInfo(
        <div>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</div>
      )
      setTiktokBrandedInfoTerms(
        <div>
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink2')}
          </a>{' '}
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink3')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}
          </a>
        </div>
      )
    } else if (tiktokYourBrand && tiktokBrandedContent) {
      //setTiktokYourBrand(false)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</>
      )
      setTiktokBrandedInfoTerms(
        <>
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink2')}
          </a>{' '}
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink3')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}
          </a>
        </>
      )
    } else {
      // setTiktokYourBrand(false)
      setTiktokBrandedInfo(t('stepMyPublication.tiktokBrandedInfo'))
      setTiktokBrandedInfoTerms('')
    }
  }, [t])

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',')
      const header = parts[0]
      const base64Data = parts[1]

      // Extrair o formato do arquivo e o tipo de conteúdo
      const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)

      const fileContentType = matches[1]
      const fileFormat = fileContentType.split('/')[1]

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    let body = extractImageData(data)

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body, selectedValuesString)

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data
    } else {
      console.log(publicationImageBucket?.responseStatus)
    }
  }

  const handleClick = async (value) => {
    console.log('value22', value)
    if (value === 3 && !tiktokCreatorInfo) {
      const tiktokCreatorInfoData = await TiktokService.getTiktokCreatorInfo(
        clientData.businessId
      )

      if (tiktokCreatorInfoData.dataT.error.code !== 'ok') {
        notifyErrorCustom(t('stepFinish.error.tiktokCreatorInfo'))
        return
      }

      setTiktokCreatorInfo(tiktokCreatorInfoData.dataT.data)

      setDropDownTiktok(
        tiktokCreatorInfoData.dataT.data.privacy_level_options.map((item) => ({
          label: item,
          value: item,
        }))
      )
    }

    if (value === 5 && facebookPages.length <= 0) {
      const retFacebookPages = await MetaService.getFacebookPages(
        clientData.businessId
      )
      if (retFacebookPages.responseStatus !== 200) {
        return
      }

      for (const page of retFacebookPages.data.data) {
        setFacebookPages((prevState) => [
          ...prevState,
          {
            name: page.name,
            tokenPage: page.access_token,
            id: page.id,
          },
        ])
      }
    } else if (value === 5 && facebookPages) {
      setFacebookPages([])
    }
    if (value == 3) {
      setValueItem(4)
    }
    if (value == 4) {
      setValueItem(2)
    }

    if (value <= 2) {
      setValueItem(1)
    }
    // Verifica se o valor já está no array
    if (selectedValues[value]) {
      // Se já estiver no objeto, remove o valor
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      // Se não estiver no objeto, adiciona o valor
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  const handleTiktokContentDisclosure = () => {
    // if (tiktokPrivacyLevel === 'SELF_ONLY') {
    //   setTiktokContentDisclosure(false)
    //   setTiktokYourBrand(false)
    //   setTiktokBrandedContent(false)
    //   notifyErrorCustom(
    //     'Configorações de "Divulgação de Conteúdo" não pode ser habilitada para publicações privadas'
    //   )
    //   return
    // }

    if (tiktokContentDisclosure) {
      setTiktokContentDisclosure(false)
      setTiktokYourBrand(false)
      setTiktokBrandedContent(false)
      setTiktokBrandedInfo(t('stepMyPublication.tiktokBrandedInfo'))
      setTiktokBrandedInfoTerms('')
    } else {
      setTiktokContentDisclosure(true)
    }
  }

  const handleYourBrand = () => {
    if (!tiktokYourBrand && !tiktokBrandedContent) {
      setTiktokYourBrand(true)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoContentPromotional')}</>
      )
      setTiktokBrandedInfoTerms(
        <div>
          {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink2')}{' '}
          </a>
        </div>
      )
    } else if (!tiktokYourBrand && tiktokBrandedContent) {
      setTiktokYourBrand(true)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</>
      )
      setTiktokBrandedInfoTerms(
        <div>
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink2')}
          </a>{' '}
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink3')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}
          </a>
        </div>
      )
    } else if (tiktokYourBrand && tiktokBrandedContent) {
      setTiktokYourBrand(false)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</>
      )
      setTiktokBrandedInfoTerms(
        <>
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink2')}
          </a>{' '}
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink3')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}
          </a>
        </>
      )
    } else {
      setTiktokYourBrand(false)
      setTiktokBrandedInfo(t('stepMyPublication.tiktokBrandedInfo'))
      setTiktokBrandedInfoTerms('')
    }
  }

  const handleYourBrandingContent = () => {
    if (!tiktokBrandedContent && !tiktokYourBrand) {
      setTiktokBrandedContent(true)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</>
      )
      setTiktokBrandedInfoTerms(
        <>
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink2')}
          </a>{' '}
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink3')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}
          </a>
        </>
      )
    } else if (!tiktokBrandedContent && tiktokYourBrand) {
      setTiktokBrandedContent(true)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</>
      )
      setTiktokBrandedInfoTerms(
        <>
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink2')}
          </a>{' '}
          {t('stepMyPublication.tiktokBrandedInfoPaidPartnershipLink3')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink3')}
          </a>
        </>
      )
    } else if (tiktokBrandedContent && tiktokYourBrand) {
      setTiktokBrandedContent(false)
      setTiktokBrandedInfo(
        <>{t('stepMyPublication.tiktokBrandedInfoPaidPartnership')}</>
      )
      setTiktokBrandedInfoTerms(
        <>
          {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink')}{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            {' '}
            {t('stepMyPublication.tiktokBrandedInfoContentPromotionalLink2')}
          </a>
        </>
      )
    } else {
      setTiktokBrandedContent(false)
      setTiktokBrandedInfo(t('stepMyPublication.tiktokBrandedInfo'))
      setTiktokBrandedInfoTerms('')
    }
  }

  // const handleYourBrand = () => {
  //   if (!tiktokYourBrand && !tiktokBrandedContent) {
  //     setTiktokYourBrand(true)
  //     setTiktokBrandedInfo(
  //       <>
  //         Sua foto/vídeo será rotulado como "Conteúdo promocional"
  //         <br></br>Ao selecionar a opção "Música", você concorda com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
  //           rel="noreferrer"
  //         >
  //           Confirmação de Uso de Música do TikTok
  //         </a>
  //       </>
  //     )
  //   } else if (!tiktokYourBrand && tiktokBrandedContent) {
  //     setTiktokYourBrand(true)
  //     setTiktokBrandedInfo(
  //       <>
  //         Sua foto/vídeo será rotulado como "Parceria paga"
  //         <br></br>
  //         Ao postar, você concorda com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/bc-policy/en"
  //           rel="noreferrer"
  //         >
  //           Política de conteúdo de marca
  //         </a>{' '}
  //         e com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
  //           rel="noreferrer"
  //         >
  //           Confirmação de Uso de Música do TikTok
  //         </a>
  //       </>
  //     )
  //   } else if (tiktokYourBrand && tiktokBrandedContent) {
  //     setTiktokYourBrand(false)
  //     setTiktokBrandedInfo(
  //       <>
  //         Sua foto/vídeo será rotulado como "Parceria paga"
  //         <br></br>
  //         Ao postar, você concorda com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/bc-policy/en"
  //           rel="noreferrer"
  //         >
  //           Política de conteúdo de marca
  //         </a>{' '}
  //         e com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
  //           rel="noreferrer"
  //         >
  //           Confirmação de Uso de Música do TikTok
  //         </a>
  //       </>
  //     )
  //   } else {
  //     setTiktokYourBrand(false)
  //     setTiktokBrandedInfo(
  //       'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
  //     )
  //   }
  // }

  // const handleYourBrandingContent = () => {
  //   if (!tiktokBrandedContent && !tiktokYourBrand) {
  //     setTiktokBrandedContent(true)
  //     setTiktokBrandedInfo(
  //       <>
  //         Sua foto/vídeo será rotulado como "Parceria paga"
  //         <br></br>
  //         Ao postar, você concorda com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/bc-policy/en"
  //           rel="noreferrer"
  //         >
  //           Política de conteúdo de marca
  //         </a>{' '}
  //         e com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
  //           rel="noreferrer"
  //         >
  //           Confirmação de Uso de Música do TikTok
  //         </a>
  //       </>
  //     )
  //   } else if (!tiktokBrandedContent && tiktokYourBrand) {
  //     setTiktokBrandedContent(true)
  //     setTiktokBrandedInfo(
  //       <>
  //         Sua foto/vídeo será rotulado como "Parceria paga"
  //         <br></br>
  //         Ao postar, você concorda com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/bc-policy/en"
  //           rel="noreferrer"
  //         >
  //           Política de conteúdo de marca
  //         </a>{' '}
  //         e com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
  //           rel="noreferrer"
  //         >
  //           Confirmação de Uso de Música do TikTok
  //         </a>
  //       </>
  //     )
  //   } else if (tiktokBrandedContent && tiktokYourBrand) {
  //     setTiktokBrandedContent(false)
  //     setTiktokBrandedInfo(
  //       <>
  //         Sua foto/vídeo será rotulado como "Conteúdo promocional"
  //         <br></br>Ao selecionar a opção "Música", você concorda com a{' '}
  //         <a
  //           target="_blank"
  //           href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
  //           rel="noreferrer"
  //         >
  //           Confirmação de Uso de Música do TikTok
  //         </a>
  //       </>
  //     )
  //   } else {
  //     setTiktokBrandedContent(false)
  //     setTiktokBrandedInfo(
  //       'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
  //     )
  //   }
  // }

  const handlePrivacyLevel = (value) => {
    // if (value === 'SELF_ONLY') {
    //   setTiktokContentDisclosure(false)
    //   setTiktokYourBrand(false)
    //   setTiktokBrandedContent(false)
    //   notifyWarningCustom(
    //     'Configorações de "Divulgação de Conteúdo" não pode ser habilitada para publicações privadas'
    //   )
    // }
    setTiktokPrivacyLevel(value)
  }

  const handleSavePublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')

      setLoading(false)
      return
    }

    if (!selectedValuesString.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!')
      setLoading(false)
      return
    }

    if (selectedValues[3] && !tiktokPrivacyLevel) {
      notifyErrorCustom(
        'Selecione a privacidade da sua publicação para o TikTok'
      )
      setLoading(false)
      return
    }

    if (tiktokContentDisclosure && !tiktokYourBrand && !tiktokBrandedContent) {
      notifyErrorCustom(
        'Ao habilitar "Divulgação de Conteúdo" do TikTok você precisa informar se seu conteudo divulga "Sua marca" ou "Conteúdo de marca"'
      )
      setLoading(false)
      return
    }

    if (!confirmTerms && selectedValues[3]) {
      setLoading(false)
      setOpenTerms(true)
      return
    }

    // const dateSelected = new Date(selectedDate)

    // dateSelected.setUTCHours(valueHour)
    // dateSelected.setUTCMinutes(valueMinute)

    const dateSelected = dayjs(selectedDate).hour(valueHour).minute(valueMinute)

    const imageUrlBuket = ImageLogoSrc?.src
      ? `data:image/JPEG;base64,${ImageLogoSrc?.src}`
      : imageUrl

    let publicationImageBucket = await returnUploadImageBucket(imageUrlBuket)

    if (publicationImageBucket) {
      let publicationBody = {
        headline: imageLayers.params
          ? imageLayers?.params.filter((item) => item?.name === 'titulo')[0]
              ?.value
          : '',
        legend: legend,
        publicationImageUrl: publicationImageBucket?.fileUrl,
        publicationImageUrlName: publicationImageBucket?.fileName,
        classification: currentIndex,
        sketch: false,
        publicationDate: dateSelected,
        scheduleDate: dateSelected,
        objective: purposeOfPublicationRef?.current,
        businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
        socialTypes: selectedValuesString,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
        generateArtJson: imageLayers,
        publicationMode: publicationTypePost
          ? JSON.stringify(publicationTypePost)
          : 'FEED',
        facebookPageToken: facebookPageToken,
      }

      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      //return

      const publicationResponse = await PublicationService.postPublication(
        businessId,
        selectedValuesString,
        publicationBody
      )

      if (publicationResponse?.responseStatus === 200) {
        if (selectedValues[3]) {
          let bodyTiktokPublication = {
            publicationId: publicationResponse.data.uuid,
            privacyLevel: tiktokPrivacyLevel,
            disableComment: !tiktokComment,
            musicImage: tiktokMusic,
            brandContentToggle: tiktokBrandedContent,
            brandOrganicToggle: tiktokYourBrand,
          }

          const tiktokPublicationReturn =
            await TiktokService.postTiktokPublication(bodyTiktokPublication)
        }
        publicationIdRef.current = [
          { uuid: publicationResponse?.data?.uuid, classification: null },
        ]
      } else {
        // notifyErrorCustom('Erro ao salvar publicação!')
        // return
      }

      let headlineHasPublicationBody = {
        publicationId: publicationResponse?.data?.uuid,
        purposePublication: '',
        publicationDescription: '',
        productId: productRef?.current?.uuid,
        targetAudience: targetAudienceRef?.current?.uuid,
      }

      // const headlinePublicationRetur =
      await HeadlineHasPublicationService.postHeadlineHasPublication(
        headlineHasPublicationBody
      )

      if (
        publicationResponse?.responseStatus === 200 ||
        publicationResponse?.responseStatus === 204
      ) {
        notifySuccessCustom('Publicação agendada com sucesso!')
        setStep('stepPublicationSuccess')
      } else {
        setLoading(false)
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    } else {
      notifyErrorCustom('Erro ao salvar arte!')
      setLoading(false)
      return
    }

    setLoading(false)
  }

  const handleEditPublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')

      setLoading(false)
      return
    }

    if (!selectedValuesString.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!')
      setLoading(false)
      return
    }

    if (tiktokContentDisclosure && !tiktokYourBrand && !tiktokBrandedContent) {
      notifyErrorCustom(
        'Ao habilitar "Divulgação de Conteúdo" do TikTok você precisa informar se seu conteudo divulga "Sua marca" ou "Conteúdo de marca"'
      )
      setLoading(false)
      return
    }

    if (!confirmTerms && selectedValues[3]) {
      setLoading(false)
      setOpenTerms(true)
      return
    }

    // const dateSelected = new Date(selectedDate)

    // dateSelected.setUTCHours(valueHour)
    // dateSelected.setUTCMinutes(valueMinute)

    const dateSelected = dayjs(selectedDate).hour(valueHour).minute(valueMinute)

    //!--------------------------

    const imageUrlBuket = ImageLogoSrc?.src
      ? `data:image/png;base64,${ImageLogoSrc?.src}`
      : imageUrl

    let publicationImageBucket = await returnUploadImageBucket(imageUrlBuket)

    //!--------------------------

    if (publicationImageBucket) {
      let publicationBody = {
        ...editDataRef.current,

        publicationImageUrl: publicationImageBucket?.fileUrl,
        publicationImageUrlName: publicationImageBucket?.fileName,
        classification: editDataRef?.current?.classification,
        publicationDate: dateSelected,
        scheduleDate: dateSelected,
        socialTypes: selectedValuesString,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
        generateArtJson: editDataRef?.current?.generateArtJson,
      }

      delete publicationBody.updatedAt
      delete publicationBody.deletedAt
      delete publicationBody.uuid
      delete publicationBody.createdAt
      delete publicationBody.socialTypes
      delete publicationBody.campaignId
      delete publicationBody.userAccountId
      delete publicationBody.hashtag
      delete publicationBody.link
      delete publicationBody.messagePermission
      delete publicationBody.objective
      delete publicationBody.businessClientId

      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      console.log('editDataRef?.current?.uuid',editDataRef?.current?.uuid)
      console.log('businessId',businessId)
      console.log('selectedValuesString',selectedValuesString)
      console.log('publicationBody',publicationBody)

      try {
        await PublicationService.putPublication(
          editDataRef?.current?.uuid,
          businessId,
          selectedValuesString,
          publicationBody
        )
        notifySuccessCustom('Publicação agendada com sucesso!')
        setStep('stepPublicationSuccess')
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    } else {
      notifyErrorCustom('Erro ao salvar arte!')
      setLoading(false)
      return
    }

    setLoading(false)
  }

  const [valueItim, setValueItim] = useState(1)

  // const handleSelectedIntem = (item) => {
  //   // campaignRef.current = item
  //   setValueItim(item.id)
  // }

  const handleSelectedIntem = (k, v) => {
    setPublicationTypePost((prevState) => ({ ...prevState, [k]: v }))
    if (v.length > 0) {
      const lastItem = v[v.length - 1]
      setValueItem(lastItem.id)
    }
  }

  const handleSelectedFacebookPage = (value) => {
    setFacebookPageToken(value)
  }

  const [gptInput, setGptInput] = useState('')

  const loadGptOutput = async () => {
    // console.log('gptInput ====> ', gptInput)

    let businesData = undefined
    let voiceToneData = ''

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businesData = data[0]

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusinessId(
        businesData?.uuid
      )
    } else {
      const { data } = await BusinessService.getBusinessByLogin()

      businesData = data[0]

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusiness()
    }

    // console.log('voiceToneData ====> ', voiceToneData?.data[0]?.tone)

    const body = {
      flow: 'own_publication',
      prompt: 'legend',

      niche: businesData?.BusinessNiche?.name,
      subniche: businesData?.subNiche,
      input: gptInput,
      voice_tone: voiceToneData?.data[0]?.tone,
      business_description: businesData?.description,
    }

    // console.log('body ====> ', body)

    const response = await postPromptGPT(body)

    // console.log('response ====> ', response)

    if (response?.data?.status === 200) {
      setLegend(response?.data?.data?.legenda)
    } else {
      notifyErrorCustom('Erro ao gerar legenda!')
    }

    setLoading(false)
  }

  const ScheduleInfoField = () => {
    const hours = [
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00 ',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00 ',
      '00:00',
    ]
    const storage = JSON.parse(
      window.localStorage.getItem('userData')
    ).userEmail
    const userInfo = {
      username: storage, // REQUIRED, must be unique
    }
    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          // overflowY: 'auto',
          //WebkitOverflowScrolling: 'touch',
          //scrollbarWidth: 'none',
          //msOverflowStyle: 'none',
          // height: 'calc(100vh - 176px)',
          // backgroundColor: 'red',
        }}
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            // height: 'calc(82vh - (-180px))',
            height: 'calc(100vh - 176px)',
            // height: '800px',
            // backgroundColor: 'red',
          }}
        >
          <div className={styles.card}>
            <ProductFruits
              workspaceCode="FzMKHdjfiHGoWeMW"
              language="en"
              user={userInfo}
            />
            <TikTokModal
              isOpen={openTerms}
              setOpenTerms={setOpenTerms}
              setConfirmTerms={setConfirmTerms}
              handleFinish={handleFinish}
            />
            <div className={styles.showPath}>
              <Picture color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>
                {t('new_publication_page_finish_pass_post_on')}
              </p>
            </div>

            <div className={styles.iconsField}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(5)}
              >
                <Facebook
                  color={selectedValues[5] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(1)}
              >
                <Linkedin
                  color={selectedValues[1] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(4)}
              >
                <Instagram
                  color={selectedValues[4] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(3)}
              >
                <TikTok
                  color={selectedValues[3] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(2)}
              >
                <XTwitter
                  color={selectedValues[2] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              {/* <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div> */}
            </div>
          </div>

          {dashParam && (
            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
                cursor: 'default',
              }}
              className={styles.selectedModalCard}
            >
              <div
                className={styles.form_container_input}
                style={{
                  cursor: 'default',
                  width: '100%',
                }}
              >
                <label
                  style={{
                    fontSize: 14,
                    color: '#190027',
                    fontWeight: 500,
                  }}
                >
                  Legenda
                </label>

                <textarea
                  readOnly={false}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  value={legend}
                  style={{
                    width: '100%',
                    height: 130,
                    borderRadius: 6,
                    border: '1px solid #ededed',
                    backgroundColor: 'rgba(224, 224, 224, 1)',
                    padding: '10px',
                    color: '#373737',
                    resize: 'none',
                    outline: 'none',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                  }}
                  onChange={(e) => setLegend(e.target.value)}
                  placeholder="Digite aqui a legenda desta publicação"
                />

                {/* <div
                className={styles.textarea}
                style={{
                  overflowY: 'auto',
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                  }}
                >
                  {publicationLegend === undefined || publicationLegend === ''
                    ? 'Clique em aplicar para gerar legenda...'
                    : publicationLegend}
                </div>
              </div> */}

                <div
                  style={{
                    display: 'flex',
                    gap: 20,
                  }}
                >
                  <input
                    value={gptInput}
                    onChange={(e) => setGptInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setLoading(true)
                        //loadGptOutput()
                      }
                    }}
                    type="text"
                    placeholder="Digite sobre seu post e clique em aplicar para que a IA te de uma sugestão de legenda"
                    style={{
                      width: '100%',
                      height: 40,
                      borderRadius: 5,
                      border: '1px solid #ededed',
                      backgroundColor: '#ededed',
                      padding: '0 10px',
                      marginTop: 10,
                      marginBottom: 20,

                      color: '#190027',
                    }}
                  />
                  <div
                    onClick={() => {
                      setLoading(true)
                      loadGptOutput()
                    }}
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      height: 40,
                      width: 100,
                      borderRadius: 5,
                      backgroundColor: '#6A0098',

                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: 14,
                      }}
                    >
                      Aplicar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Calendar2 color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>
                {t('new_publication_page_finish_pass_date_and_hour')}
              </p>
            </div>

            <div className={styles.box_calendar}>
              <div
                style={{
                  flex: 1,
                }}
              >
                <SchedulerCalendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>

              <div className={styles.container_hour_carousel}>
                <p className={styles.container_hour_carousel_title}>
                  {t('new_publication_page_finish_pass_hour')}
                </p>
                <CarouselHours
                  items={hours}
                  setValueMinute={setValueMinute}
                  valueMinute={valueMinute}
                  setValueHour={setValueHour}
                  valueHour={valueHour}
                />
              </div>
            </div>
          </div>

          {tiktokCreatorInfo !== false && selectedValues[3] && imageUrl && (
            <div
              style={{
                marginTop: 30,
              }}
              className={styles.card}
            >
              <div className={styles.showPath}>
                <Subtract color={'#6A0098'} width={20} height={20} />
                <TikTok
                  color={selectedValues[3] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
                <p className={styles.patchTitle}>
                  {`Configurações do TikTok - ${tiktokCreatorInfo.creator_nickname}`}
                </p>

                <img
                  style={{
                    maxWidth: '40px',
                    borderRadius: '200px',
                  }}
                  src={`${tiktokCreatorInfo.creator_avatar_url}`}
                />
              </div>

              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.warningCardTiktok}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>
                      {t('stepMyPublication.warning')}
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {t('stepMyPublication.warning_description')}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <LockClosed width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.privacy_tiktok_label')}
                    <SelectSquare
                      htmlFor="select"
                      placeholder="Selecione a privacidade da sua publicação"
                      padding="10px 10px 10px 15px"
                      // value={tiktokPrivacyLevel}
                      tiktokBrandedContent={tiktokBrandedContent}
                      options={dropDownTiktok}
                      onClickItem={({ value }) => {
                        handlePrivacyLevel(value)
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokComment
                    ? '#f5eaf9'
                    : tiktokCreatorInfo.comment_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: tiktokCreatorInfo.comment_disabled
                    ? 'none'
                    : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokComment
                    ? setTiktokComment(false)
                    : setTiktokComment(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokComment
                      ? '#6A0098'
                      : tiktokCreatorInfo.comment_disabled
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.comment')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.comment_description')}
                  </div>
                </div>
              </div>
              {/* {imageUrl && (
                <div
                  style={{
                    marginTop: 30,
                    backgroundColor: '#fff8c4',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    borderColor: '#ffd700',
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <TikTokIcon width={12} height={12} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        {t('stepMyPublication.warning')}
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {t('stepMyPublication.music_confirmation_link')}
                        <a
                          target="_blank"
                          href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                          rel="noreferrer"
                        >
                          {' '}
                          {t(
                            'stepMyPublication.tiktokBrandedInfoContentPromotionalLink2'
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              {imageUrl && (
                <div
                  style={{
                    marginTop: 18,
                    backgroundColor: tiktokMusic ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                  className={styles.selectedModalCard}
                  onClick={() =>
                    tiktokMusic ? setTiktokMusic(false) : setTiktokMusic(true)
                  }
                >
                  <div
                    style={{
                      width: 34,
                      height: 34,
                      border: '1px solid #dcdcdc',
                      borderRadius: 200,
                      backgroundColor: tiktokMusic ? '#6A0098' : '#FBFBFB', //'#6A0098',
                    }}
                  ></div>

                  <div>
                    <div className={styles.selectedModalTextTitle}>Música</div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {t('stepMyPublication.music_description')}
                      {tiktokMusic && (
                        <div className={styles.selectedModalTextSubtitle}>
                          <label
                            className={styles.checkboxLabel}
                            onClick={(e) => {
                              return
                            }}
                          >
                            <input
                              type="checkbox"
                              className={styles.checkbox}
                              onClick={(e) => {
                                e.stopPropagation()
                                tiktokMusicCheckbox
                                  ? setTiktokMusicCheckbox(false)
                                  : setTiktokMusicCheckbox(true)
                              }}
                            />
                            {t('stepMyPublication.music_confirmation_link')}{' '}
                            <a
                              target="_blank"
                              href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                              rel="noreferrer"
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                            >
                              {' '}
                              {t(
                                'stepMyPublication.tiktokBrandedInfoContentPromotionalLink2'
                              )}
                            </a>
                          </label>
                        </div>
                      )}
                    </div>
                    {/*{tiktokMusic !== false && (
                    <div className={styles.warningBox}>
                      {t('stepMyPublication.music_confirmation')}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        {t('stepMyPublication.music_confirmation_link')}
                      </a>
                      .
                    </div>
                  )}*/}
                  </div>
                </div>
              )}
              {/* {videoUrl && (
                  <div
                    style={{
                      marginTop: 18,
                      backgroundColor: tiktokDuet
                        ? '#f5eaf9'
                        : tiktokCreatorInfo.duet_disabled
                        ? '#edeae8'
                        : '#FBFBFB', //'#6A0098',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                      pointerEvents: tiktokCreatorInfo.duet_disabled
                        ? 'none'
                        : 'auto',
                      opacity: '0.5 : 1',
                    }}
                    className={styles.selectedModalCard}
                    onClick={() =>
                      tiktokDuet ? setTiktokDuet(false) : setTiktokDuet(true)
                    }
                  >
                    <div
                      style={{
                        width: 34,
                        height: 34,
                        border: '1px solid #dcdcdc',
                        borderRadius: 200,
                        backgroundColor: tiktokDuet
                          ? '#6A0098'
                          : tiktokCreatorInfo.duet_disabled
                          ? '#edeae8'
                          : '#FBFBFB', //'#6A0098',
                      }}
                    ></div>
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        {t('stepMyPublication.duet')}
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {t('stepMyPublication.duet_description')}
                      </div>
                    </div>
                  </div>
                )}
                {videoUrl && (
                  <div
                    style={{
                      marginTop: 18,
                      backgroundColor: tiktokStitch
                        ? '#f5eaf9'
                        : tiktokCreatorInfo.stitch_disabled
                        ? '#edeae8'
                        : '#FBFBFB', //'#6A0098',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                      pointerEvents: tiktokCreatorInfo.stitch_disabled
                        ? 'none'
                        : 'auto',
                      opacity: '0.5 : 1',
                    }}
                    className={styles.selectedModalCard}
                    onClick={() =>
                      tiktokStitch
                        ? setTiktokStitch(false)
                        : setTiktokStitch(true)
                    }
                  >
                    <div
                      style={{
                        width: 34,
                        height: 34,
                        border: '1px solid #dcdcdc',
                        borderRadius: 200,
                        backgroundColor: tiktokStitch
                          ? '#6A0098'
                          : tiktokCreatorInfo.stitch_disabled
                          ? '#edeae8'
                          : '#FBFBFB', //'#6A0098',
                      }}
                    ></div>
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        Stitch
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {t('stepMyPublication.stitch_description')}
                      </div>
                    </div>
                  </div>
                )} */}
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokContentDisclosure
                    ? '#f5eaf9'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  // pointerEvents: tiktokCreatorInfo.stitch_disabled
                  //   ? 'none'
                  //   : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={
                  () => handleTiktokContentDisclosure()
                  // tiktokContentDisclosure
                  //   ? setTiktokContentDisclosure(false)
                  //   : setTiktokContentDisclosure(true),
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokContentDisclosure
                      ? '#6A0098'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.contentDisclosure')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.contentDisclosure_description')}
                  </div>
                </div>
              </div>
              {tiktokContentDisclosure && (
                <div
                  style={{
                    marginTop: 30,
                    backgroundColor: '#fff8c4',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    borderColor: '#ffd700',
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <TikTokIcon width={12} height={12} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        {t('stepMyPublication.warning')}
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {tiktokBrandedInfo}
                        <br />
                        {t('stepMyPublication.tiktokBrandedInfoPosted')}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokYourBrand
                    ? '#f5eaf9'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() => handleYourBrand()}
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokYourBrand
                      ? '#6A0098'
                      : !tiktokContentDisclosure
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.yourBrand')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.yourBrand_description')}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokBrandedContent
                    ? '#f5eaf9'
                    : !tiktokContentDisclosure ||
                      tiktokPrivacyLevel === 'SELF_ONLY'
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents:
                    !tiktokContentDisclosure ||
                    tiktokPrivacyLevel === 'SELF_ONLY'
                      ? 'none'
                      : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() => handleYourBrandingContent()}
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokBrandedContent
                      ? '#6A0098'
                      : !tiktokContentDisclosure ||
                        tiktokPrivacyLevel === 'SELF_ONLY'
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.brandedContent')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.brandedContent_description')}
                  </div>
                </div>
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {tiktokBrandedInfoTerms}
              </div>
            </div>
          )}
          {selectedValues[2] && imageUrl && (
            <div
              style={{
                marginTop: 30,
              }}
              className={styles.card}
            >
              <div className={styles.showPath}>
                <Subtract color={'#6A0098'} width={20} height={20} />
                <XTwitter color={'#6A0098'} width={24} height={24} />
                <p className={styles.patchTitle}>{'Configurações do X'}</p>
              </div>

              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.warningCardTiktok}
              >
                <div>
                  <XTwitter color={'#6A0098'} width={24} height={24} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>
                      {'Aviso sobre o limite de caracteres:'}
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {
                        '* Postagens de contas padrão no X estão limitadas a 280 caracteres.'
                      }
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {
                        '* Para contas com assinatura do X Blue, o limite é de até 10.000 caracteres.'
                      }
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {
                        'Certifique-se de que sua postagem está dentro do limite permitido antes de enviá-la.'
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedValues[1] && imageUrl && (
            <div
              style={{
                marginTop: 30,
              }}
              className={styles.card}
            >
              <div className={styles.showPath}>
                <Subtract color={'#6A0098'} width={20} height={20} />
                <Linkedin color={'#6A0098'} width={24} height={24} />
                <p className={styles.patchTitle}>
                  {'Configurações do Linkedin'}
                </p>
              </div>

              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <LockClosed width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextSubtitle}>
                    <SelectSquare
                      htmlFor="select"
                      placeholder={t('stepMyPublication.selectPrivacyLevel')}
                      padding="10px 10px 10px 15px"
                      // value={tiktokPrivacyLevel}
                      // tiktokContentDisclosure={tiktokContentDisclosure}
                      options={[
                        {
                          id: 1,
                          label: 'Público',
                          value: 'PUBLIC',
                        },
                        {
                          id: 2,
                          label: 'Conexões',
                          value: 'CONNECTIONS',
                        },
                      ]}
                      onClickItem={({ value }) => {
                        handlePrivacyLevel(value)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedValues[4] && imageUrl && (
            <div
              style={{
                marginTop: 30,
              }}
              className={styles.card}
            >
              <div className={styles.showPath}>
                <Subtract color={'#6A0098'} width={20} height={20} />
                <Instagram color={'#6A0098'} width={24} height={24} />
                <p className={styles.patchTitle}>
                  {'Configurações do Instagram'}
                </p>
              </div>
              {imageUrl && (
                <div
                  style={{
                    marginTop: 30,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <Picture color={'#6A0098'} width={20} height={20} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div className={styles.selectedModalTextSubtitle}>
                      {'Selecione o tipo de publicação'}
                      <MultiplySelectSquare
                        backgroundColor={'#EDEDED'}
                        border={true}
                        borderColor={'#dadada'}
                        placeholder={'Selecione'}
                        arrowDirection="down"
                        variante="gray"
                        options={[
                          {
                            id: 2,
                            label: 'Feed',
                            value: 'FEED',
                          },
                          {
                            id: 3,
                            label: 'Stories',
                            value: 'STORIES',
                          },
                        ]}
                        fontSize={12}
                        // onClickItem={handleSelectedIntem}
                        onClickItem={(value) => {
                          handleSelectedIntem('instagramItems', value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* {videoUrl && (
                <div
                  style={{
                    marginTop: 30,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <Picture color={'#6A0098'} width={20} height={20} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div className={styles.selectedModalTextSubtitle}>
                      <MultiplySelectSquare
                        htmlFor="select"
                        // backgroundColor={'#EDEDED'}
                        // border={true}
                        // borderColor={'#dadada'}
                        placeholder={'Selecione o tipo de publicação'}
                        arrowDirection="down"
                        variante="gray"
                        options={
                          selectedValues[1]
                            ? [
                                {
                                  id: 1,
                                  label: 'Feed',
                                  value: 'FEED',
                                },
                              ]
                            : [
                                {
                                  id: 1,
                                  label: 'Feed',
                                  value: 'FEED',
                                },
                                {
                                  id: 2,
                                  label: 'Stories',
                                  value: 'STORIES',
                                },
                              ]
                        }
                        fontSize={12}
                        onClickItem={(value) => {
                          handlePublicationModeMovie('instagramItems', value)
                        }}
                        // onClickItem={handlePublicationModeMovie}
                        defaultSelected={[
                          {
                            id: 1,
                            label: 'Feed',
                            value: 'FEED',
                          },
                          {
                            id: 2,
                            label: 'Stories',
                            value: 'STORIES',
                          },
                        ].find((item) => item?.id === publicationMovieType)}
                      />
                    </div>
                  </div>
                </div>
              )} */}

              <div
                onClick={() => setOppenedModalPersons(true)}
                style={{
                  marginTop: 18,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <MorePersonsIcon color={'#610f92'} size={'24'} />
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.markPeople')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.markPeople_description')}
                  </div>
                </div>
              </div>

              <div
                onClick={() => setOppennedModalHashtags(true)}
                style={{
                  marginTop: 18,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <HashtagIcon color={'#610f92'} size={'22'} />
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.addHashtags')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.addHashtags_description')}
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedValues[5] && imageUrl && (
            <div
              style={{
                marginTop: 30,
              }}
              className={styles.card}
            >
              <div className={styles.showPath}>
                <Subtract color={'#6A0098'} width={20} height={20} />
                <Facebook color={'#6A0098'} width={24} height={24} />
                <p className={styles.patchTitle}>
                  {'Configurações do Facebook'}
                </p>
              </div>

              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <User color={'#6A0098'} width={20} height={20} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextTitle}>
                    <p style={{ margin: 0 }}>
                      {t('stepMyPublication.select_page')}
                    </p>
                    <SelectSquare
                      backgroundColor={'#EDEDED'}
                      border={true}
                      borderColor={'#dadada'}
                      placeholder={'Selecione a página'}
                      arrowDirection="down"
                      options={facebookPages.map((page) => {
                        return {
                          label: page.name,
                          value: page.tokenPage,
                          id: page.id,
                        }
                      })}
                      fontSize={12}
                      onClickItem={(value) => {
                        handleSelectedFacebookPage(value)
                      }}
                    />
                  </div>
                </div>
              </div>

              {imageUrl && (
                <div
                  style={{
                    marginTop: 30,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <Picture color={'#6A0098'} width={20} height={20} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div className={styles.selectedModalTextTitle}>
                      <p style={{ margin: 0 }}>
                        {t('stepMyPublication.publication_type')}
                      </p>
                      <MultiplySelectSquare
                        backgroundColor={'#EDEDED'}
                        border={true}
                        borderColor={'#dadada'}
                        placeholder={'Selecione o tipo de publicação'}
                        arrowDirection="down"
                        variante="gray"
                        options={[
                          {
                            id: 2,
                            label: 'Feed',
                            value: 'REELS',
                          },
                          {
                            id: 3,
                            label: 'Stories',
                            value: 'STORIES',
                          },
                        ]}
                        fontSize={12}
                        // onClickItem={handleSelectedIntem}
                        onClickItem={(value) => {
                          handleSelectedIntem('facebookItems', value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* {videoUrl && (
              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <Picture color={'#6A0098'} width={20} height={20} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextTitle}>
                    <p style={{ margin: 0 }}>{t('stepMyPublication.publication_type')}</p>
                    <MultiplySelectSquare
                      htmlFor="select"
                      // backgroundColor={'#EDEDED'}
                      // border={true}
                      // borderColor={'#dadada'}
                      placeholder={'Selecione o tipo de publicação'}
                      arrowDirection="down"
                      variante="gray"
                      options={[
                        {
                          id: 1,
                          label: 'Reels',
                          value: 'REELS',
                        },
                        {
                          id: 2,
                          label: 'Stories',
                          value: 'STORIES',
                        },
                      ]}
                      fontSize={12}
                      onClickItem={(value) => {
                        handlePublicationModeMovie('facebookItems', value)
                      }}
                      // onClickItem={handlePublicationModeMovie}
                      defaultSelected={[
                        {
                          id: 1,
                          label: 'Reels',
                          value: 'REELS',
                        },
                        {
                          id: 2,
                          label: 'Stories',
                          value: 'STORIES',
                        },
                      ].find((item) => item?.id === publicationMovieType)}
                    />
                  </div>
                </div>
              </div>
            )} */}

              {/* title facebook */}
              {/* <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <PencilSquare color={'#6A0098'} width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextTitle}>
                    <p style={{ margin: 0 }}>{t('stepMyPublication.title')}</p>
                    <input
                      value={tiktokHeadline}
                      onChange={(e) => setTiktokHeadline(e.target.value)}
                      type="text"
                      placeholder={t('stepMyPublication.placeholder_title')}
                      style={{
                        width: '100%',
                        height: 40,
                        borderRadius: 5,
                        border: '1px solid #ededed',
                        backgroundColor: '#ededed',
                        padding: '0 10px',
                        marginTop: 3,
                        marginBottom: 3,

                        color: '#190027',
                      }}
                    />
                  </div>
                </div>
              </div> */}

              {/* <div
              onClick={() => setOppenedModalLocale(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <PinMapsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.location')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.location_description')}
                </div>
              </div>
            </div> */}

              {/* <div
              onClick={() => setOppenedModalPersons(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <MorePersonsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.markPeople')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.markPeople_description')}
                </div>
              </div>
            </div> */}

              {/* <div
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <FacebookChatIcon color={'#610f92'} size={'24'} />

              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.receiveMessages')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.receiveMessages_description')}
                </div>
              </div>
            </div> */}

              {/* <div
              onClick={() => setOppennedModalLinks(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <LinksIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.addLinks')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.addLinks_description')}
                </div>
              </div>
            </div> */}

              {/* <div
              onClick={() => setOppennedModalHashtags(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <HashtagIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.addHashtags')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.addHashtags_description')}
                </div>
              </div>
            </div> */}
            </div>
          )}

          {/* {tiktokCreatorInfo !== false && selectedValues[3] && (
            <div
              style={{
                marginTop: 30,
              }}
              className={styles.card}
            >
              <div className={styles.showPath}>
                <Subtract color={'#AAAAAA'} width={20} height={20} />
                <p className={styles.patchTitle}>{`${t(
                  'new_publication_page_finish_pass_tiktok_settings'
                )} - ${tiktokCreatorInfo.creator_nickname}`}</p>
                <img
                  style={{
                    maxWidth: '40px',
                    borderRadius: '200px',
                  }}
                  src={`${tiktokCreatorInfo.creator_avatar_url}`}
                />
              </div>
              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.warningCardTiktok}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>{t()}</div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {t(
                        'new_publication_page_finish_pass_tiktok_warning_subtitle'
                      )}
                      {/* <br></br>* Ao selecionar a opção "Música", você concorda
                      com a{' '}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        Confirmação de Uso de Música do TikTok
                      </a> */}
          {/* </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <LockClosed width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    height: 45,
                  }}
                >
                  <div className={styles.selectedModalTextSubtitle}>
                    <SelectSquare
                      htmlFor="select"
                      placeholder={t(
                        'new_publication_page_finish_pass_tiktok_privacy_level'
                      )}
                      tiktokContentDisclosure={tiktokContentDisclosure}
                      padding="10px 10px 10px 15px"
                      value={dropDownTiktok?.value}
                      options={dropDownTiktok}
                      onClickItem={({ value }) => {
                        handlePrivacyLevel(value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokComment
                    ? '#f5eaf9'
                    : tiktokCreatorInfo.comment_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: tiktokCreatorInfo.comment_disabled
                    ? 'none'
                    : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokComment
                    ? setTiktokComment(false)
                    : setTiktokComment(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokComment
                      ? '#6A0098'
                      : tiktokCreatorInfo.comment_disabled
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('new_publication_page_finish_pass_tiktok_comment')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_comment_subtitle'
                    )}
                  </div>
                </div>
              </div>
              {imageUrl && (
                <div
                  style={{
                    marginTop: 30,
                    backgroundColor: '#fff8c4',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    borderColor: '#ffd700',
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <TikTokIcon width={12} height={12} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        {t(
                          'new_publication_page_finish_pass_tiktok_music_title'
                        )}
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {t(
                          'new_publication_page_finish_pass_tiktok_music_description'
                        )}{' '}
                        <a
                          target="_blank"
                          href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                          rel="noreferrer"
                        >
                          {' '}
                          {t(
                            'new_publication_page_finish_pass_tiktok_music_description_link'
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {!videoUrl && ( */}
          {/* <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokMusic ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokMusic ? setTiktokMusic(false) : setTiktokMusic(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokMusic ? '#6A0098' : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('new_publication_page_finish_pass_tiktok_music')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_music_subtitle'
                    )}
                  </div> */}
          {/* {tiktokMusic !== false && (
                    <div className={styles.warningBox}>
                      Ao selecionar, você concorda com a{' '}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        Confirmação de Uso de Música do TikTok
                      </a>
                      .
                    </div>
                  )} */}
          {/* </div>
              </div>
              )} */}
          {/* {videoUrl && (
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokDuet
                    ? '#f5eaf9'
                    : tiktokCreatorInfo.duet_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: tiktokCreatorInfo.duet_disabled
                    ? 'none'
                    : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokDuet ? setTiktokDuet(false) : setTiktokDuet(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokDuet
                      ? '#6A0098'
                      : tiktokCreatorInfo.duet_disabled
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>Dueto</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    Permitir que outros usuários façam dueto com sua publicação.
                  </div>
                </div>
              </div>
            )}
            {videoUrl && (
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokStitch
                    ? '#f5eaf9'
                    : tiktokCreatorInfo.stitch_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: tiktokCreatorInfo.stitch_disabled
                    ? 'none'
                    : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokStitch ? setTiktokStitch(false) : setTiktokStitch(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokStitch
                      ? '#6A0098'
                      : tiktokCreatorInfo.stitch_disabled
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>Stitch</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    Permitir que outros usuários façam stitch com sua
                    publicação.
                  </div>
                </div>
              </div>
            )} */}
          {/* <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokContentDisclosure
                    ? '#f5eaf9'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  // pointerEvents: tiktokCreatorInfo.stitch_disabled
                  //   ? 'none'
                  //   : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={
                  () => handleTiktokContentDisclosure()
                  // tiktokContentDisclosure
                  //   ? setTiktokContentDisclosure(false)
                  //   : setTiktokContentDisclosure(true),
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokContentDisclosure
                      ? '#6A0098'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_content_disclosure'
                    )}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_content_disclosure_subtitle'
                    )}
                  </div>
                </div>
              </div>

              {tiktokContentDisclosure && (
                <div
                  style={{
                    marginTop: 30,
                    backgroundColor: '#fff8c4',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    borderColor: '#ffd700',
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <TikTokIcon width={12} height={12} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        {t(
                          'new_publication_page_finish_pass_tiktok_warning_title'
                        )}
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {tiktokBrandedInfo}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

          {/* <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokYourBrand
                    ? '#f5eaf9'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() => handleYourBrand()}
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokYourBrand
                      ? '#6A0098'
                      : !tiktokContentDisclosure
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('new_publication_page_finish_pass_tiktok_your_brand')}{' '}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_your_brand_subtitle'
                    )}
                  </div>
                </div>
              </div> */}

          {/* <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokBrandedContent
                    ? '#f5eaf9'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() => handleYourBrandingContent()}
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokBrandedContent
                      ? '#6A0098'
                      : !tiktokContentDisclosure
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_branded_content'
                    )}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t(
                      'new_publication_page_finish_pass_tiktok_branded_content_subtitle'
                    )}
                  </div>
                </div>
              </div> */}
          {/* </div>
          )} */}

          {/* <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>
                {t('new_publication_page_finish_pass_config_addctional')}
              </p>
            </div>
            <div
              onClick={() => setOppenedModalLocale(true)}
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <PinMapsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_location')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('new_publication_page_finish_pass_location_subtitle')}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppenedModalPersons(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <MorePersonsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_mark_people')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('new_publication_page_finish_pass_mark_people_subtitle')}
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <FacebookChatIcon color={'#610f92'} size={'24'} />

              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_receive_messages')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t(
                    'new_publication_page_finish_pass_receive_messages_subtitle'
                  )}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalLinks(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <LinksIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_add_links')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('new_publication_page_finish_pass_add_links_subtitle')}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalHashtags(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <HashtagIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_add_hashtags')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('new_publication_page_finish_pass_add_hashtags_subtitle')}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>
                {'Configurações de Privacidade'}
              </p>
            </div>

            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_audience')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('new_publication_page_finish_pass_audience_subtitle')}
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('new_publication_page_finish_pass_restricted')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('new_publication_page_finish_pass_restricted_subtitle')}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }

  const SchedulePhotosField = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: 480,
          marginLeft: 20,
          gap: 20,
        }}
      >
        <Loading enable={loading} />
        {/* <div className={styles.container_select_preview}>
          <SelectSquare
            backgroundColor={'#EDEDED'}
            border={true}
            borderColor={'#dadada'}
            placeholder={'Selecione'}
            arrowDirection="down"
            options={[
              {
                id: 1,
                label: 'Previa Feed Do Facebook',
              },
              {
                id: 2,
                label: 'Previa Feed Do Instagram',
              },
              {
                id: 3,
                label: 'Previa Stories Do Facebook',
              },
              {
                id: 4,
                label: 'Previa Stories Do Instagram',
              },
            ]}
            fontSize={12}
            //  defaultSelected={locationsStatesOptions.find(
            //    (e) => e.value === locationsStatesOptions?.value
            //  )}
            onClickItem={handleSelectedIntem}
          />
        </div> */}
        <div
          style={{
            backgroundColor: '#fbfbfb',
            flex: 1,
          }}
        >
          {/* <ImageRender image={imageUrl} type={valueItim} legend={legend} /> */}
          <ImageRender
            image={imageUrl}
            type={valueItem}
            legend={legend}
            setValueItem={setValueItem}
            selectedValues={selectedValues}
          />
        </div>
        {/* <div><CarrouselComponent /></div> */}
      </div>
    )
  }

  const ButtonsBottomField = () => {
    return (
      <div
        style={{
          height: 70,
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              if (location?.state?.dashboad) {
                return navigate(-1)
              }

              if (editRef) {
                return navigate(-1)
              }

              setStep('stepSubtitles')
            }}
          >
            <p className={styles.button_title}>
              {t('new_publication_page_finish_pass_back')}
            </p>
          </button>

          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <button
              className={
                tiktokContentDisclosure &&
                !tiktokYourBrand &&
                !tiktokBrandedContent
                  ? styles.button_next_disabled
                  : styles.button_next
              }
              onClick={handleFinish}
              onMouseEnter={() => setShowTooltipFinish(true)} // Mostrar o tooltip ao passar o mouse
              onMouseLeave={() => setShowTooltipFinish(false)} // Ocultar o tooltip quando sair
            >
              <p className={styles.button_title}>
                {t('new_publication_page_finish_pass_finish')}
              </p>
            </button>
            {showTooltipFinish &&
              tiktokContentDisclosure &&
              !tiktokYourBrand &&
              !tiktokBrandedContent && (
                <div className={styles.tooltip}>
                  {t('stepMyPublication.finish_tooltip')}
                </div>
              )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container_page}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {ScheduleInfoField()}
          {SchedulePhotosField()}
        </div>

        <ButtonsBottomField />

        <ModalComponent
          isOpen={oppenedModalLocale}
          onClose={() => setOppenedModalLocale(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>
                {t('new_publication_page_finish_pass_location')}
              </div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Digite aqui..."
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t('new_publication_page_finish_pass_finish')}
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppenedModalPersons}
          onClose={() => setOppenedModalPersons(false)}
          style={{
            width: 600,
            height: 280,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Marcar Pessoas</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <div className={styles.iconsField} style={{ marginTop: 20 }}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Facebook color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Linkedin color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Instagram color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <XTwitter color={'#AAAAAA'} width={24} height={24} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Quem Você gostaria de Marcar?"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalLinks}
          onClose={() => setOppennedModalLinks(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Link</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira um link público e válido aqui"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalHashtags}
          onClose={() => setOppennedModalHashtags(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Hashtags</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira as hashtags e separe as mesmas com uma vírgula (,)"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t('new_publication_page_finish_pass_finish')}
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  )
}

export default StepFinish

// StepFinish.propTypes = {}
